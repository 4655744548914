import React from 'react';
import { Icon } from '@aider/ui';
import Button from './Button';
import styled from 'styled-components';
import tw from 'twin.macro';

const PricingCard = ({
	title,
	description,
	price,
	buttonTitle,
	buttonHref,
	buttonVariant,
	features,
	isPremium,
}) => {
	return (
		<Container
			isPremium={isPremium}
			className="relative flex flex-col justify-between h-full"
		>
			<div
				className={`flex flex-col h-full overflow-hidden rounded-xl bg-white ${
					isPremium ? '' : 'border border-gray-300'
				}`}
			>
				<div className="p-6 flex-grow">
					<h3 className="font-semibold text-lg mb-2">{title}</h3>
					<p className="text-gray-700">{description}</p>
					<div className="py-4">{price}</div>
				</div>

				{/* Button Section */}
				<div className="p-6 mt-auto">
					<Button
						variant={buttonVariant}
						title={buttonTitle}
						href={buttonHref}
						className="block w-full text-center"
					/>
				</div>

				{/* Features Section */}
				{features && (
					<div className="bg-gray-100 p-6">
						<p className="font-medium mb-4">Vad ingår?</p>
						<ul>
							{features.map((feature, index) => (
								<li
									key={index}
									className="flex space-x-2 items-center mb-2"
								>
									<Icon
										name="checkbox-circle"
										className="fill-current text-blue-500 w-5 h-5"
									/>
									<span className="font-medium">{feature}</span>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</Container>
	);
};

const Container = styled.div`
	&:after {
		content: '';
		border-radius: 16px;
		display: ${({ isPremium }) => (isPremium ? 'block' : 'none')};
		width: calc(100% + 8px);
		height: calc(100% + 8px);
		position: absolute;
		top: -4px;
		left: -4px;
		z-index: -1;
		${tw`bg-gradient-to-r from-blue-500 to-green-500`}
	}
`;

export default PricingCard;