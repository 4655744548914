import React from 'react';
import { Icon } from '@aider/ui';

const FeatureListItem = ({ title }) => {
	return (
		<li className="flex items-center py-4 border-t border-gray-300">
			<p className="w-1/3 text-base text-black font-medium">{title}</p>
			<div className="w-2/3 flex justify-center">
				<div className="flex items-center space-x-2">
					<Icon
						name="checkbox-circle"
						className="h-5 w-5 fill-current text-blue-500"
					/>
					<p className="text-sm font-medium">Ingår</p>
				</div>
			</div>
		</li>
	);
};

export default FeatureListItem;
