import React, { Fragment } from 'react'
import Seo from '../components/Seo'
import FeatureListItem from '../components/FeatureListItem'
import PricingCard from '../components/PricingCard'

import {
	GRUND_FEATURES,
	PREMIUM_FEATURES,
	FEATURE_LIST,
} from '../utils/featureData'

const Pricing = () => (
	<Fragment>
		<Seo
			title="Förenkla uppdraget som god man."
			description="Bokföring, dagbok & körjournal, årsräkning med mera. Allt samlat i en flexibel lösning för mobil och dator, skyddat med inloggning via BankID."
		/>
		{/* Hero Section */}
		<div className="container mx-auto pt-8 pb-2">
			<section className="flex flex-col items-center md:w-8/12 lg:w-6/12 px-4 pt-12 mb-12 lg:mb-24 text-center md:mx-auto">
				<h1 className="text-3xl mb-4 md:text-4xl lg:text-5xl font-futura font-semibold leading-tight">
					Abonnemang för alla uppdrag
				</h1>
				<p className="text-base md:text-lg text-gray-800">
					I våra appar administrerar du smidigt och säkert ditt
					uppdrag som ställföreträdare.
				</p>
			</section>
		</div>
		{/* Subscription Cards */}
		<section className="bg-gray-100 transform -skew-y-3 pt-12 mt-24 md:mt-48 mb-24">
			<div className="transform skew-y-3 -mt-24 md:-mt-48 pb-12 md:pb-24">
				<div className="container mx-auto px-4">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-5xl mx-auto">
						<PricingCard
							isPremium
							title="Premium"
							description="En digital helhetstjänst för dig med uppdrag som frivillig ställföreträdare."
							buttonTitle="Prova 30 dagar"
							buttonHref="https://app.aider.nu/skapa-konto"
							buttonVariant="secondary"
							price={
								<div>
									<p className="font-medium text-gray-600">
										Pris, 1 – 5 huvudmän:{' '}
										<span className="text-black">
											225 kr
										</span>{' '}
										per huvudman och år.
									</p>
									<p className="font-medium text-gray-600">
										Pris, 6 – 20 huvudmän:{' '}
										<span className="text-black">
											1 350 kr
										</span>{' '}
										per år.
									</p>
								</div>
							}
							features={GRUND_FEATURES}
						/>
						<PricingCard
							title="Professionell"
							description="En digital helhetstjänst för professionella ställföreträdare i privat och offentlig regi."
							buttonTitle="Kontakta oss"
							buttonHref="mailto:info@aider.nu"
							buttonVariant="primary"
							price={
								<p className="font-medium text-gray-600">
									Kontakta oss för ett prisförslag.
								</p>
							}
							features={PREMIUM_FEATURES}
						/>
					</div>
				</div>
			</div>
		</section>

		{/* Subscription Cards */}
		<section className="mb-24">
			<div className="container mx-auto px-4">
				<div className="mx-auto w-full lg:w-10/12">
					{/* Header Section */}
					<div className="hidden md:grid grid-cols-3 bg-white py-6 border-b border-gray-300 sticky top-0">
						<div></div> {/* Empty column for alignment */}
						<div className="col-span-2 flex justify-center">
							<h4 className="text-lg font-semibold">
								Premium & Professionell
							</h4>
						</div>
					</div>
					{/* Features List */}
					{FEATURE_LIST.map(({ title, features }, index) => (
						<div key={title + index} className="mb-12">
							<h3 className="font-semibold text-lg mb-4">
								{title}
							</h3>
							<ul>
								{features.map((feature, idx) => (
									<FeatureListItem
										key={feature + idx}
										title={feature}
									/>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>
		</section>
	</Fragment>
)

export default Pricing
