export const GRUND_FEATURES = [
	'Smidig bokföring och import av transaktioner',
	'Dagbok och körjournal',
	'Rapportgenerator, för års- och sluträkning',
	'Hjälpcenter och chatt',
	'Säker inloggning och krypterad lagring',
	'Uppdateringar och nyhetsbrev',
]

export const PREMIUM_FEATURES = [
	'Smidig bokföring och import av transaktioner',
	'Dagbok och körjournal',
	'Rapportgenerator, för års- och sluträkning',
	'Hjälpcenter, chatt och telefonsupport',
	'Säker inloggning och krypterad lagring',
	'Uppdateringar och nyhetsbrev',
]

export const FEATURE_LIST = [
	{
		title: 'Allmänt',
		features: [
			'Applikation för iPhone',
			'Applikation för Android',
			'Applikation för webb',
			'Inloggning med BankID',
			'Automatiska uppdateringar',
			'Tjänstens samtliga funktioner på svenska',
		],
	},
	{
		title: 'Dagbok och körjournal',
		features: [
			'Strukturerad dagboksjournal',
			'Körjournal',
		],
	},
	{
		title: 'Meddelanden',
		features: [
			'Säker tvåvägs-kommunikation med Överförmyndaren',
			'Anmäl intresse för nya uppdrag via Meddelanden',
			'Bifoga dokument och underlag via Meddelanden',
		],
	},
	{
		title: 'Support',
		features: [
			'Integrerat Hjälpcenter för utbildning, guider och instruktioner',
			'Integrerad chatt-funktion för stöd och support',
		],
	},
	{
		title: 'Säkerhet och data',
		features: [
			'Uppkoppling mot Folkbokföringen för verifiering av persondata',
			'Säker lagring och backup av alla data',
			'Strukturerad export av all data om du vill avsluta ditt konto',
			'Kryptering av data vid överföringar',
		],
	},
	{
		title: 'Redovisning',
		features: [
			'Import av transaktionsdata via fil från Sveriges ledande banker',
			'Import av transaktionsdata via BankID från utvalda svenska banker',
			'Bokföring med smarta funktioner för inkomst- och utgiftskategorisering',
			'Flervalsfunktioner för att dela upp transaktioner i flera kategorier',
			'Funktion för hantering av brutto- och nettobelopp',
			'Möjlighet att bifoga digitalt underlag till alla transaktioner',
			'Digital lagring av alla transaktionsunderlag',
			'Inskanning av transaktionsunderlag via mobilkamera',
			'Anteckningsmöjlighet i fritext kopplat till alla transaktioner',
			'Översiktlig sammanställning av bokföringen i siffror',
			'Översiktlig sammanställning av bokföringen i grafisk form',
			'Funktioner för filtrering och sök, baserat på tid, kategori m.m.',
			'Verktyg för generering av lagstyrd årsräkning',
			'Hjälpfunktioner för feleliminering i samband med rapportgenerering',
			'Automatiskt genererad kassabok',
			'Alla verifikat, noteringar och underlag sparas digitalt',
			'Automatisk funktion för krypterad sammanställning av rapporter, underlag och digitala verifikat',
			'Automatisk sammanställning av strukturerade rapporter, underlag och verfikat',
			'Funktion för utskrift av rapporter, underlag och verifikat',
		],
	},
];